<template>
  <div id="paginaTabelleApplicazioneCompensi">
    <!-- Elementi Pagina----------------------------------------------------------------------------------- -->
    <b-row class="match-height">
      <!-- Card Grid View: GridElencotabellaApplicazioneCompensi -->
      <b-col xl="12" md="12">
        <b-card tag="article" class="mb-2">
          <b-card-header class="align-items-baseline">
            <div>
              <b-card-title>Elenco Tabelle Applicazione Compensi</b-card-title>
              <b-card-text class="mb-0"> Centro Componenti TV </b-card-text>
            </div>
            <!-- dropdown menù laterale dx -->
            <b-dropdown
              variant="link"
              no-caret
              right
              class="chart-dropdown"
              toggle-class="p-0"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item href="#"> ... </b-dropdown-item>
            </b-dropdown>
          </b-card-header>
          <b-card-body class="mb-0">
            <!-- card body -->
            <b-row>
              <!-- Timer Alert -->
              <b-col xl="6" md="12">
                <b-alert
                  :show="alertADismissCountDown"
                  dismissible
                  variant="warning"
                  @dismissed="alertADismissCountDown = 0"
                  @dismiss-count-down="alertACountDownChanged"
                >
                  <p>Occorre selezionare una riga della tabella</p>
                  <b-progress
                    variant="warning"
                    :max="alertADismissSecs"
                    :value="alertADismissCountDown"
                    height="4px"
                  />
                </b-alert>
              </b-col>

              <b-col xl="12" md="12" class="pt-1 text-center" v-show="loading">
                <b-spinner label="Loading..."></b-spinner>
              </b-col>
              <b-col xl="12" md="12" class="pt-1">
                <ejs-grid
                  id="gridA"
                  ref="gridA"
                  :dataSource="tabellaApplicazioneCompensiFullData"
                  :allowSorting="true"
                  :sortSettings="gridA.sortOptions"
                  :toolbarClick="gridAClickHandler"
                  :toolbar="gridA.toolbarOptions"
                  :height="gridA.height"
                  :allowFiltering="true"
                  :filterSettings="gridA.filterOptions"
                  :editSettings="gridA.editOptions"
                  :actionBegin="gridAactionBegin"
                  :actionComplete="gridAactionComplete"
                >
                  <e-columns>
                    <e-column
                      field="id"
                      headerText="ID"
                      :isPrimaryKey="true"
                      width="60"
                    ></e-column>
                    <e-column
                      field="dataInizio"
                      headerText="Data Inizio"
                      width="100"
                      type="date"
                      :format="settings.formatoData"
                    ></e-column>
                    <e-column
                      field="dataFine"
                      headerText="Data Fine"
                      width="100"
                      type="date"
                      :format="settings.formatoData"
                    ></e-column>
                    <e-column
                      field="nome"
                      headerText="Nome"
                      width="100"
                    ></e-column>
                    <e-column
                      field="classePrioritaria"
                      headerText="Classe Prioritaria"
                      width="100"
                      foreignKeyField="val"
                      foreignKeyValue="label"
                      :dataSource="this.classePrioritariaList"
                    ></e-column>
                  </e-columns>
                </ejs-grid>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- dialogA: dialogDettagliotabellaApplicazioneCompensi ---------------------------------------------------------------------------------------------------- -->
    <ejs-dialog
      id="dialogA"
      ref="dialogA"
      :header="dialogA.header"
      :buttons="dialogA.buttons"
      :visible="dialogA.visible"
      :showCloseIcon="dialogA.showCloseIcon"
      :close="dialogAClose"
      :open="dialogAOpen"
      :zIndex="dialogA.zIndex"
      :animationSettings="dialogA.animationSettings"
      :isModal="dialogA.isModal"
      :beforeOpen="spreadsheetCreated"
      v-on:submit.prevent="noop"
    >
      <!-- Content dialog -->
      <content>
        <b-tabs ref="tabAdialogA" v-model="dialogA.tabAdialogAIndex">
          <b-tab title="Tabella Applicazione Compensi" @click="tabClicked('dialogATab01')">
            <b-row class="match-height">
              <!-- Card con Elementi Input: anagrafica -->
              <b-col xl="12" md="12">
                <b-card tag="article" class="mb-2">
                  <b-card-header class="align-items-baseline">
                    <div>
                      <b-card-title>Dati Tabella Applicazione Compensi</b-card-title>
                      <b-card-text class="mb-0" />
                    </div>
                  </b-card-header>
                  <b-card-body class="mb-0">
                    <!-- card body -->
                    <b-row>
                      <!-- Input di tipo testo: tabellaApplicazioneCompensiActiveData.id  -->
                      <b-col xl="12" md="12" class="mb-2">
                        <label for="id" class="h5">Id</label>
                        <ejs-textbox
                          id="id"
                          v-model="tabellaApplicazioneCompensiActiveData.id"
                          floatLabelType="Never"
                          cssClass=""
                          placeholder="id"
                          showClearButton="true"
                          type="text"
                          autocomplete="false"
                          :readonly="true"
                        />
                      </b-col>

                      <!-- Input di tipo data: tabellaApplicazioneCompensiActiveData.dataInizio  -->
                      <b-col xl="12" md="12" class="mb-2">
                        <label for="dataInizio" class="h5">Data Inizio</label>
                        <ejs-datepicker
                          id="dataInizio"
                          v-model="tabellaApplicazioneCompensiActiveData.dataInizio"
                          :format="settings.formatoDatepicker"
                          placeholder="Data Inizio"
                          :firstDayOfWeek="settings.firstDayOfWeek"
                        />
                      </b-col>

                      <!-- Input di tipo data: tabellaApplicazioneCompensiActiveData.dataFine  -->
                      <b-col xl="12" md="12" class="mb-2">
                        <label for="dataFine" class="h5">Data Fine</label>
                        <ejs-datepicker
                          id="dataFine"
                          v-model="tabellaApplicazioneCompensiActiveData.dataFine"
                          :format="settings.formatoDatepicker"
                          placeholder="Data Fine"
                          :firstDayOfWeek="settings.firstDayOfWeek"
                        />
                      </b-col>

                      <!-- Input di tipo dropdown senza azioni senza template: tabellaApplicazioneCompensiActiveData.classePrioritaria  -->
                      <b-col xl="12" md="12" class="mb-2">
                        <label for="classePrioritaria" class="h5"
                          >Classe Prioritaria</label
                        >
                        <ejs-dropdownlist
                          id="classePrioritaria"
                          v-model="tabellaApplicazioneCompensiActiveData.classePrioritaria"
                          :dataSource="classePrioritariaList"
                          :fields="labelValFields"
                          placeholder="Selezione Classe Prioritaria"
                          showClearButton="true"
                        />
                      </b-col>

                      <!-- Input di tipo testo: tabellaApplicazioneCompensiActiveData.nome  -->
                      <b-col xl="12" md="12" class="mb-2">
                        <label for="nome" class="h5">Nome</label>
                        <ejs-textbox
                          id="nome"
                          v-model="tabellaApplicazioneCompensiActiveData.nome"
                          floatLabelType="Never"
                          cssClass=""
                          placeholder="Nome"
                          showClearButton="true"
                          type="text"
                          autocomplete="false"
                        />
                      </b-col>

                      <b-col xl="2" md="4" class="text-left mt-1">
                        <ejs-button
                          id="salvaButton"
                          @click.native="saveSpreadsheet()"
                          >Salva Tabella</ejs-button
                        >
                      </b-col>

                      <!-- Input di tipo spreadsheet: tabellaApplicazioneCompensiActiveData.tabella  -->
                      <ejs-spreadsheet
                        ref="spreadsheet"
                        :showRibbon="false"
                        :showFormulaBar="false"
                        :showSheetTabs="false"
                        :actionComplete="saveSpreadsheet"
                      >
                      </ejs-spreadsheet>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </content>
    </ejs-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import useJwt from "@/auth/jwt/useJwt";
// import bootstrap-Vue
import {
  LayoutPlugin,
  TabsPlugin,
  CardPlugin,
  DropdownPlugin,
  LinkPlugin,
  AlertPlugin,
  ProgressPlugin,
  ToastPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormCheckboxPlugin,
  FormSelectPlugin,
  FormRatingPlugin,
  SpinnerPlugin,
} from "bootstrap-vue";
// import Syncfusion
import { enableRipple } from "@syncfusion/ej2-base";
import { Query } from "@syncfusion/ej2-data";
// import {
//   extend, addClass, createElement, isNullOrUndefined, detach, EventHandler,
// } from '@syncfusion/ej2-base'
import {
  DateTimePickerPlugin,
  DatePickerPlugin,
  TimePickerPlugin,
  CalendarPlugin,
} from "@syncfusion/ej2-vue-calendars";
import {
  ButtonPlugin,
  CheckBoxPlugin,
  SwitchPlugin,
} from "@syncfusion/ej2-vue-buttons";
import {
  TextBoxPlugin,
  NumericTextBoxPlugin,
  MaskedTextBoxPlugin,
  UploaderPlugin,
} from "@syncfusion/ej2-vue-inputs";
import {
  DropDownListPlugin,
  MultiSelectPlugin,
  ListBoxPlugin,
  CheckBoxSelection,
} from "@syncfusion/ej2-vue-dropdowns";
import { SpreadsheetPlugin } from "@syncfusion/ej2-vue-spreadsheet";
import { DialogPlugin } from "@syncfusion/ej2-vue-popups";

import {
  GridPlugin,
  ForeignKey,
  Edit as GridEdit,
  Toolbar as GridToolbar,
  Sort as GridSort,
  Filter as GridFilter,
} from "@syncfusion/ej2-vue-grids";
import { DropDownButtonPlugin } from "@syncfusion/ej2-vue-splitbuttons";

const axios = require("axios");
// use bootstrap-vue
Vue.use(TabsPlugin);
Vue.use(CardPlugin);
Vue.use(LayoutPlugin);
Vue.use(DropdownPlugin);
Vue.use(LinkPlugin);
Vue.use(AlertPlugin);
Vue.use(ProgressPlugin);
Vue.use(ToastPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormRatingPlugin);
Vue.use(SpinnerPlugin);
// use Syncfusion
Vue.use(TextBoxPlugin);
Vue.use(NumericTextBoxPlugin);
Vue.use(MaskedTextBoxPlugin);
Vue.use(UploaderPlugin);
Vue.use(DatePickerPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(TimePickerPlugin);
Vue.use(DropDownListPlugin);
Vue.use(MultiSelectPlugin);
Vue.use(ButtonPlugin);
Vue.use(CheckBoxPlugin);
Vue.use(SwitchPlugin);
Vue.use(SpreadsheetPlugin);
Vue.use(DialogPlugin);
Vue.use(GridPlugin);
Vue.use(DropDownButtonPlugin);
Vue.use(ListBoxPlugin);
Vue.use(CalendarPlugin);
enableRipple(true);
// api
const apiVueEndPoint = process.env.VUE_APP_API_ENDPOINT;
const apiTabelleApplicazioneCompensiUrl = "/main/api/tabellaApplicazioneCompensi";

export default Vue.extend({
  components: {},
  data() {
    return {
      // spinner
      loading: false,
      // tmp
      nonMostrare: false, // utile per non cancellare codice in editing
      // generali
      settings: {
        formatoData: { type: "date", format: "dd-MM-yyyy" }, // da usare per grid
        formatoDatepicker: "dd-MM-yyyy", // da usare per i datepicker
        formatoOra: "HH:mm",
        intervalloOra: 15,
        listboxSelectionSettings: { showCheckbox: true },
      },
      // struttura etichetta - valore per dropdown
      labelValFields: { value: "val", text: "label" },
      // soggetti
      tabellaApplicazioneCompensiFullData: [], // elenco completo dati Tabella Applicazione Compensi
      tabellaApplicazioneCompensiActiveId: 0, // Id Tabella Applicazione Compensi attivo (0 se nuovo)
      tabellaApplicazioneCompensiActiveData: {}, // dati completi della Tabella Applicazione Compensi attiva
      tabellaApplicazioneCompensiSavedData: {}, // dati completi della Tabella Applicazione Compensi attiva
      tabellaApplicazioneCompensiDefaultData: {}, // dati completi di default per nuove Tabella Applicazione Compensi

      // per classePrioritaria
      classePrioritariaList: [
        { label: "Priorità Alta", val: "alta" },
        { label: "Priorità Media", val: "media" },
        { label: "Priorità Bassa", val: "bassa" },
      ],

      // per alertA
      alertADismissSecs: 3,
      alertADismissCountDown: 0,

      // per dialogA
      dialogA: {
        header: "",
        visible: false,
        showCloseIcon: true,
        buttons: [
          {
            click: this.dialogAOk,
            buttonModel: { content: "OK", isPrimary: true },
          },
          { click: this.dialogACancel, buttonModel: { content: "Cancel" } },
        ],
        animationSettings: { effect: "FadeZoom", duration: 400, delay: 0 }, // { effect: 'None' }, // selezionata per primo livello
        zIndex: 1050,
        tabAdialogAIndex: 0,
        isModal: false,
      },
      // per gridA
      gridAFlag: false,
      gridA: {
        height: 300,
        editOptions: {
          allowEditing: false,
          allowDeleting: true,
        },
        filterOptions: { type: "Excel" },
        toolbarOptions: [
          {
            text: "Nuovo",
            tooltipText: "Inserisci nuova Tabella Applicazione Compensi",
            prefixIcon: "e-plus",
            id: "new",
          },
          {
            text: "Apri",
            tooltipText: "Modifica Tabella Applicazione Compensi",
            prefixIcon: "e-expand",
            id: "edit",
          },
          {
            text: "Cancella",
            tooltipText: "Cancella Tabella Applicazione Compensi",
            prefixIcon: "e-cancel",
            id: "delete",
          },
          "Search",
        ],
        sortOptions: { columns: [{ field: "id", direction: "Ascending" }] },
      },
    };
  },
  created() {
    console.log("Pagina Tabella Applicazione Compensi Creata");
    this.loading = true;
    console.log(this.loading);
    console.log("Recupero elenchi dal backend");
    // tabellaApplicazioneCompensiFullData
    // recupero elenco Tabella Applicazione Compensi dal backend
    if (localStorage.getItem("userData") !== null) {
      console.log("Recupero elenco Tabella Applicazione Compensi dal backend");
      useJwt.get(apiTabelleApplicazioneCompensiUrl).then((d) => {
        console.log("Tabella Applicazione Compensi");
        console.log(d.data);
        this.tabellaApplicazioneCompensiFullData = d.data;
        this.loading = false;
      });
    } else {
      console.log("Non trovo UserData, prova ancora");
    }

    // Recupero/definisco i valori di default delle entità utilizzate nel componente

    // tabellaApplicazioneCompensiDefaultData ( esplicitato nei pulsanti "Nuovo.." della gridA )
    this.tabellaApplicazioneCompensiDefaultData = {};
  },

  provide: {
    // per grid
    grid: [GridToolbar, GridSort, GridFilter, GridEdit, ForeignKey],
    // per listBox
    listbox: [CheckBoxSelection],
    // per multiselect
    multiselect: [CheckBoxSelection],
  },
  methods: {
    // per dialogA
    dialogAOpen(args) {
      console.log("Apertura dialogA");
      // assegnare le informazioni da visualizzare nel dialog A
      console.log("Tabella Applicazione Compensi attiva");
      console.log(this.tabellaApplicazioneCompensiActiveId);
      console.log(this.tabellaApplicazioneCompensiActiveData);
      this.tabellaApplicazioneCompensiSavedData = { ...this.tabellaApplicazioneCompensiActiveData };
      // evita chiusura dialog su pressione enter
      let dialogObj = args.element.ej2_instances[0];
      dialogObj.keyDown = this.noop.bind(this);
      dialogObj.dataBind();
      // determina l'altezza di spreadsheet
      var spreadsheet = this.$refs.spreadsheet.ej2Instances;
      spreadsheet.height = "600px";
    },
    dialogAClose() {
      console.log("Chiusura dialogA");
      this.dialogA.tabAdialogAIndex = 0;
    },
    dialogAOk() {
      console.log("Cliccato bottone OK di dialogA");
      if (this.tabellaApplicazioneCompensiActiveId === 0) {
        useJwt
          .post(apiTabelleApplicazioneCompensiUrl, this.tabellaApplicazioneCompensiActiveData)
          .then((res) => {
            //console.log(res.status);
            this.tabellaApplicazioneCompensiActiveId = res.data.id;
            return this; // restituisci contesto per permettere aggiornamento grid
          })
          .then((context) => {
            useJwt.get(apiTabelleApplicazioneCompensiUrl).then((res) => {
              console.log(
                "Aggiornamento gridA con l'aggiunta di una Tabella Applicazione Compensi"
              );
              context.tabellaApplicazioneCompensiFullData = res.data;
              context.tabellaApplicazioneCompensiFullData = [
                ...context.tabellaApplicazioneCompensiFullData,
              ];
              console.log(res.data);
            });
            // nascondi dialog solo se creazione avvenuta con successo
            context.$refs.dialogA.hide();
          })
          .catch((error) => {
            // costruzione messaggio di errore da mostrare
            const obj = JSON.parse(error.response.request.response);
            const message = Object.keys(obj)[0] + ": " + Object.values(obj)[0];
            this.makeToast("primary", "ERRORE", message);
            console.log(Object.keys(obj)[0]);
            console.log(Object.values(obj)[0]);
          });
      } else {
        // salvataggio in caso di edit
        const editUrl = `${apiTabelleApplicazioneCompensiUrl}/${this.tabellaApplicazioneCompensiActiveId}`;
        // qui devo fare caricamento dati ma non include i file da gestire con upload
        useJwt
          .patch(editUrl, this.tabellaApplicazioneCompensiActiveData)
          .then((res) => {
            console.log(res.status);
            console.log(res.data);
            return this; // restituisci contesto per permettere aggiornamento gridA
          })
          .then((context) => {
            useJwt.get(apiTabelleApplicazioneCompensiUrl).then((res) => {
              console.log(
                "Aggiornamento gridA con la modifica di una Tabella Applicazione Compensi"
              );
              context.tabellaApplicazioneCompensiFullData = res.data;
              context.tabellaApplicazioneCompensiFullData = [
                ...context.tabellaApplicazioneCompensiFullData,
              ];
            });

            // nascondi dialog solo se creazione avvenuta con successo
            context.$refs.dialogA.hide();
          })
          .catch((error) => {
            // costruzione messaggio di errore da mostrare
            // TODO: possibili errori multipli
            const obj = JSON.parse(error.response.request.response);
            const message = Object.keys(obj)[0] + ": " + Object.values(obj)[0];
            this.makeToast("primary", "ERRORE", message);
            //console.log(error.response.data.message || error.message)
            console.log(Object.keys(obj)[0]);
            console.log(Object.values(obj)[0]);
          });
      }
      // triggerare aggiornamento filtering / ordinamento? <----------------------
    },
    dialogACancel() {
      console.log("Cliccato bottone Cancel di dialogA");
      this.$refs.dialogA.hide();
    },
    dialogABeforeOpen() {
      console.log("dialogABeforeOpen");
    },
    // per gridA
    gridAClickHandler(args) {
      // pulsante NEW
      if (args.item.id === "new") {
        this.dialogA.header = "Nuova Tabella Applicazione Compensi";
        this.tabellaApplicazioneCompensiDefaultData = {
          id: 0,
          dataInizio: null,
          dataFine: null,
          classePrioritaria: null,
          nome: null,
          tabella: null,
        };
        this.tabellaApplicazioneCompensiActiveData = this.tabellaApplicazioneCompensiDefaultData;
        console.log(
          "Premuto pulsante creazione nuova Tabella Applicazione Compensi da gridA"
        );
        this.tabellaApplicazioneCompensiActiveId = 0;
        this.$refs.dialogA.show(true);
      }
      // pulsante EDIT
      else if (args.item.id === "edit") {
        console.log("Premuto pulsante modifica Tabella Applicazione Compensi da gridA");
        try {
          const selectedRow = this.$refs.gridA.getSelectedRecords();
          let found = false;
          this.tabellaApplicazioneCompensiActiveId = selectedRow[0].id;
          console.log(this.tabellaApplicazioneCompensiActiveId);
          for (const i in this.tabellaApplicazioneCompensiFullData) {
            if (
              this.tabellaApplicazioneCompensiFullData[i].id ===
              this.tabellaApplicazioneCompensiActiveId
            ) {
              this.tabellaApplicazioneCompensiActiveData = {
                ...this.tabellaApplicazioneCompensiFullData[i],
              };
              found = true;
              break;
            }
          }
          console.log("Tabella Applicazione Compensi CORRENTE");
          console.log(this.tabellaApplicazioneCompensiActiveData);
          console.log(this.tabellaApplicazioneCompensiActiveId);

          if (found) {
            console.log("Edit Tabella Applicazione Compensi");
            this.dialogA.header = "Tabella Applicazione Compensi";
            this.$refs.dialogA.show(true);
          } else {
            console.log("Elemento non trovato");
          }
        } catch (err) {
          console.log("Occorre selezionare una riga della tabella");
          this.alertAShow();
        }
      }
      // Pulsante DELETE
      else if (args.item.id === "delete") {
        try {
          console.log("Premuto pulsante elimina Tabella Applicazione Compensi da gridA");
          const selectedRow = this.$refs.gridA.getSelectedRecords();
          const deletedElementId = selectedRow[0].id;

          const deleteUrl = `${apiTabelleApplicazioneCompensiUrl}/${deletedElementId}`;
          this.$refs.gridA.ej2Instances.deleteRecord();

          useJwt.delete(deleteUrl).then((res) => {
            console.log("Eliminazione avvenuta con successo");
          });
        } catch (err) {
          console.log(err);
          console.log("Occorre selezionare una riga della tabella");
          this.alertAShow();
        }
      }
    },
    gridAactionBegin(e) {
      if (!this.gridAFlag) {
        // Add and edit operations
        if (e.requestType === "save") {
          const editedData = e.data;
          console.log(editedData);
          // The default edit operation is cancelled
          e.cancel = true;
          const editUrl = `${apiTabelleApplicazioneCompensiUrl}/${editedData.id}`;
          useJwt.patch(editUrl, editedData).then((res) => {
            console.log(res);
            if (res.status === 200) {
              // se tutto OK
              // Flag is enabled to skip this execution when grid ends add/edit
              this.gridAFlag = true;
              // If your data is updated at the server side successfully, we can call the grid endEdit() method to save the edited data to the Grid
              this.$refs.gridA.endEdit();
            } else {
              this.makeToast(
                "primary",
                "Attenzione",
                "Salvataggio Tabella Applicazione Compensi non riuscito"
              );
            }
          });
        }
      }
    },
    // Grid’s actionComplete event handler
    // Triggers after an action is completed
    gridAactionComplete(e) {
      if (e.requestType === "save") {
        // The flag is disabled after operation is successfully performed so that it can enter the condition on next execution
        this.gridAFlag = false;
      }
    },
    // per click tabs
    tabClicked(args) {
      switch (args) {
        case "dialogATab01":
          console.log("Sei nel primo tab del dialogA");
          break;
        case "dialogATab02":
          console.log("Sei nel secondo tab del dialogA");
          break;
        default:
          console.log(args);
      }
    },
    // per alert
    alertACountDownChanged(alertADismissCountDown) {
      this.alertADismissCountDown = alertADismissCountDown;
    },
    alertAShow() {
      this.alertADismissCountDown = this.alertADismissSecs;
    },
    // per toast
    makeToast(variant = null, title = "titolo", message = "messaggio") {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
        autoHideDelay: 10000,
      });
    },
    noop() {
      console.log("premuto enter");
    },
    // per spreadsheet
    spreadsheetCreated: function () {
      // console.log("Formattazione Spreadsheet");
      // // formatto la prima riga
      // this.$refs.spreadsheet.cellFormat(
      //   {
      //     fontWeight: "bold",
      //     fontSize: "12pt",
      //     backgroundColor: "#99505f",
      //     textAlign: "center",
      //     color: "#ffffff",
      //     borderBottom: "1px solid #e0e0e0",
      //   },
      //   "A1:AE1"
      // );
      // // formatto le prime due colonne
      // this.$refs.spreadsheet.cellFormat(
      //   {
      //     fontWeight: "bold",
      //     fontSize: "12pt",
      //     backgroundColor: "#FFF7CB",
      //     textAlign: "center",
      //     color: "#000000",
      //     borderBottom: "1px solid #e0e0e0",
      //   },
      //   "A2:B300"
      // );
      // carico il contenuto
      var jsonFile = this.tabellaApplicazioneCompensiActiveData.tabella;
      if (jsonFile) {
        this.$refs.spreadsheet.openFromJson({ file: jsonFile });
      } else {
        jsonFile = {
          Workbook: {
            width: "100%",
            height: "800",
            locale: "en-US",
            sheets: [
              {
                id: 1,
                name: "Sheet1",
                state: "Visible",
                colCount: 100,
                rowCount: 100,
                usedRange: {
                  colIndex: 0,
                  rowIndex: 0,
                },
                activeCell: "A1",
                frozenRows: 0,
                isProtected: false,
                showHeaders: true,
                topLeftCell: "A1",
                frozenColumns: 0,
                selectedRange: "A1:A1",
                showGridLines: true,
                paneTopLeftCell: "A1",
                protectSettings: {
                  formatRows: false,
                  insertLink: false,
                  formatCells: false,
                  selectCells: false,
                  formatColumns: false,
                },
              },
            ],
            saveUrl: "",
            cssClass: "",
            password: "",
            allowOpen: true,
            allowSave: true,
            allowWrap: true,
            cellStyle: {
              color: "#000000",
              border: "",
              fontSize: "11pt",
              borderTop: "",
              fontStyle: "normal",
              textAlign: "left",
              borderLeft: "",
              fontFamily: "Calibri",
              fontWeight: "normal",
              textIndent: "0pt",
              borderRight: "",
              borderBottom: "",
              verticalAlign: "bottom",
              textDecoration: "none",
              backgroundColor: "#ffffff",
            },
            enableRtl: false,
            allowChart: true,
            allowImage: true,
            allowMerge: true,
            showRibbon: false,
            allowDelete: true,
            allowInsert: true,
            isProtected: false,
            allowEditing: true,
            allowSorting: true,
            definedNames: [],
            allowAutoFill: true,
            allowResizing: true,
            allowUndoRedo: true,
            showAggregate: true,
            showSheetTabs: false,
            allowFiltering: true,
            allowHyperlink: true,
            allowScrolling: true,
            scrollSettings: {
              isFinite: false,
              enableVirtualization: true,
            },
            showFormulaBar: false,
            enableClipboard: true,
            activeSheetIndex: 0,
            autoFillSettings: {
              showFillOptions: true,
            },
            enableContextMenu: true,
            enablePersistence: false,
            selectionSettings: {
              mode: "Multiple",
            },
            allowCellFormatting: true,
            allowDataValidation: true,
            allowFindAndReplace: true,
            allowNumberFormatting: true,
            allowConditionalFormat: true,
            enableKeyboardShortcut: true,
            enableKeyboardNavigation: true,
          },
        };
        this.$refs.spreadsheet.openFromJson({ file: jsonFile });
      }
    },
    // per bottone spreadsheet
    saveSpreadsheet: function () {
      this.$refs.spreadsheet
        .saveAsJson()
        .then(
          (Json) =>
            (this.tabellaApplicazioneCompensiActiveData.tabella = Json["jsonObject"])
        );
      console.log("saveSpreadsheet");
    },
  },
  computed: {},
});

/* eslint-disable global-require */
</script>

<style lang="scss">
@import "~@syncfusion/ej2-base/styles/material.css";
@import "~@syncfusion/ej2-buttons/styles/material.css";
@import "~@syncfusion/ej2-vue-buttons/styles/material.css";
@import "~@syncfusion/ej2-calendars/styles/material.css";
@import "~@syncfusion/ej2-calendars/styles/datepicker/material.css";
@import "~@syncfusion/ej2-dropdowns/styles/material.css";
@import "~@syncfusion/ej2-vue-popups/styles/material.css";
@import "~@syncfusion/ej2-vue-navigations/styles/material.css";
@import "~@syncfusion/ej2-inputs/styles/material.css";
@import "~@syncfusion/ej2-vue-layouts/styles/material.css";
@import "~@syncfusion/ej2-vue-richtexteditor/styles/material.css";
@import "~@syncfusion/ej2-splitbuttons/styles/material.css";
@import "~@syncfusion/ej2-grids/styles/material.css";
@import "~@syncfusion/ej2-vue-spreadsheet/styles/material.css";
@import "~@syncfusion/ej2-schedule/styles/material.css";
@import "~@syncfusion/ej2-vue-documenteditor/styles/material.css";
@import "~@syncfusion/ej2-treegrid/styles/material.css";
@import "~@syncfusion/ej2-vue-gantt/styles/material.css";
@import "~@syncfusion/ej2-vue-diagrams/styles/material.css";
@import "~@syncfusion/ej2-vue-kanban/styles/bootstrap.css";

.e-btn-icon.e-open-icon {
  background: url("~@/assets/images/svg/open2-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-delete-icon {
  background: url("~@/assets/images/svg/garbage-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-download-icon {
  background: url("~@/assets/images/svg/download2-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-search-icon {
  background: url("~@/assets/images/svg/search-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-upload-icon {
  background: url("~@/assets/images/svg/upload-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-add-icon {
  background: url("~@/assets/images/svg/add2-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}
</style>
